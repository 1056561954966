import React, { useState, useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterList from "@material-ui/icons/FilterList";
import FlightTakeOff from "@material-ui/icons/FlightTakeoff";
import FlightLand from "@material-ui/icons/FlightLand";
import Place from "@material-ui/icons/Place";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Today from "@material-ui/icons/Restore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Badge from "@material-ui/core/Badge";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/es";

//context
import { useAuth } from "../context/AuthContext";

//utils
import fetcher from "../utils/fetcher";

// components
import Navigation from "./Layout/Navigation";
import Loader from "./Loader";

type UnlockType = {
    className?: string;
    disabled: boolean;
    label: string;
    propertyId: number;
    type: number;
    confirmMessage: string;
};

const UnlockButton = ({ className, disabled, label, propertyId, type, confirmMessage }: UnlockType) => {
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleUnlock = async () => {
        setConfirm(false);
        setLoading(true);
        try {
            await fetcher(`va/smartlock/${propertyId}`, {
                method: "POST",
                body: JSON.stringify({ doortype: type })
            });

            enqueueSnackbar(t("list.lockOpened"), {
                variant: "success",
                preventDuplicate: true
            });
        } catch (e) {
            enqueueSnackbar(t("list.lockError"), {
                variant: "error",
                preventDuplicate: true
            });
        }
        setLoading(false);
    };

    const onClick = () => {
        if (!confirm) {
            setConfirm(true);
        } else {
            handleUnlock();
        }
    };

    useEffect(() => {
        const timer = window.setInterval(() => {
            setConfirm(false);
        }, 3000);
        return () => {
            window.clearInterval(timer);
        };
    }, []);

    return (
        <Button
            variant="contained"
            color="primary"
            className={className}
            style={
                confirm
                    ? {
                          backgroundColor: "red"
                      }
                    : {}
            }
            disabled={disabled}
            onClick={onClick}
        >
            {loading ? <CircularProgress size={24} color="secondary" /> : confirm ? confirmMessage : label}
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    content: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    datePicker: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        width: 100
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    headerIcon: {
        marginRight: 5
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    },
    secondaryBar: {
        backgroundColor: "rgb(232, 232, 232)"
    },
    button: {
        marginTop: 10
    },
    status: {
        marginLeft: 5
    }
}));

type FilterType = {
    viewDate: string;
    showCheckinsToday: number;
};

function VacantList() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { auth } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    moment.locale(i18n.language);

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [propertiesList, setPropertiesList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<FilterType>({
        viewDate: moment().format("YYYY-MM-DD"),
        showCheckinsToday: 0
    });

    const [filtersCount, setFiltersCount] = useState<number>(0);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const properties = await fetcher(`va/list`, {
                    method: "POST",
                    body: JSON.stringify(filters)
                });

                setPropertiesList(properties.data);
            } catch (e) {
                enqueueSnackbar(t("misc.error"), {
                    variant: "error",
                    preventDuplicate: true
                });
            }

            setLoading(false);
        }

        fetchData();
    }, [filters, t, enqueueSnackbar]);

    const goToToday = () => {
        setFilters(prev => ({
            ...prev,
            viewDate: moment().format("YYYY-MM-DD")
        }));
    };

    const handleToggle = (event: any) => {
        setFilters(prev => ({ ...prev, showCheckinsToday: event.target.checked ? 1 : 0 }));

        setDrawerOpen(false);

        if (filters.showCheckinsToday && filters.showCheckinsToday !== 0) {
            setFiltersCount(0);
        } else {
            setFiltersCount(1);
        }
    };

    const handleDatePrev = () => {
        const past = moment(filters.viewDate).subtract(1, "days").format("YYYY-MM-DD");

        setFilters(prev => ({ ...prev, viewDate: past }));
    };

    const handleDateChange = (val: any) => {
        setFilters(prev => ({ ...prev, viewDate: moment(val).format("YYYY-MM-DD") }));
    };

    const handleDateNext = () => {
        const next = moment(filters.viewDate).add(1, "days").format("YYYY-MM-DD");

        setFilters(prev => ({ ...prev, viewDate: next }));
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Navigation
                buttons={
                    <>
                        <IconButton color="inherit" onClick={goToToday} aria-label={t("misc.today")}>
                            <Today />
                        </IconButton>

                        <IconButton color="inherit" onClick={() => setDrawerOpen(!drawerOpen)} aria-label={t("list.filter")}>
                            <Badge badgeContent={filtersCount} color="secondary">
                                <FilterList />
                            </Badge>
                        </IconButton>
                    </>
                }
                toolbarSecondary={
                    <Toolbar disableGutters={true} className={classes.secondaryBar}>
                        <IconButton onClick={handleDatePrev} style={{ marginLeft: 5 }} aria-label={t("misc.previous")}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <div className={classes.datePicker}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                                <DatePicker
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    fullWidth={true}
                                    format="DD/MM/YYYY"
                                    autoOk={true}
                                    value={filters.viewDate}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <IconButton onClick={handleDateNext} style={{ marginRight: 5 }} aria-label={t("misc.next")}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Toolbar>
                }
            />

            <main className={classes.content}>
                {propertiesList.map(property => {
                    return (
                        <Accordion key={property.propertyId}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>
                                    <b>{property.propertyName}</b> {property.propertyHasSmartLock > 0 && <FontAwesomeIcon icon="door-open" />}
                                    <span className={classes.status}>
                                        {moment(property.nextIn).isSame(filters.viewDate, "day") ? (
                                            <>
                                                <FontAwesomeIcon icon="circle" style={{ color: "red" }} /> <i>{t("list.unavailable")}</i>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon="circle" style={{ color: "green" }} /> <i>{t("list.available")}</i>
                                            </>
                                        )}
                                    </span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="div">
                                    <div className={classes.grow}>
                                        {auth.accessLevel > 1 && (
                                            <>
                                                <FlightTakeOff style={{ fontSize: 15 }} /> <b>{t("list.lastOut")}:</b>{" "}
                                                {property.lastOut ? moment(property.lastOut).format("DD/MM/YYYY HH:mm") : t("list.none")}
                                                <br />
                                                <FlightLand style={{ fontSize: 15 }} /> <b>{t("list.nextIn")}:</b>{" "}
                                                {property.nextIn ? moment(property.nextIn).format("DD/MM/YYYY HH:mm") : t("list.none")}
                                                <br />
                                            </>
                                        )}
                                        <Place style={{ fontSize: 15 }} /> <b>{t("list.address")}:</b>{" "}
                                        <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(property.propertyAddress)}>
                                            {property.propertyAddress}, {property.propertyFloor} - {property.propertyDoor}
                                        </a>
                                        {property.propertyHasSmartLock > 0 && auth.accessLevel > 0 && (
                                            <div>
                                                <UnlockButton
                                                    className={classes.button}
                                                    disabled={property.propertyHasSmartLock === 1}
                                                    propertyId={property.propertyId}
                                                    type={2}
                                                    confirmMessage={t("list.pressAgain")}
                                                    label={t("list.frontDoor")}
                                                />
                                                <br />

                                                <UnlockButton
                                                    className={classes.button}
                                                    disabled={property.propertyHasSmartLock === 3}
                                                    propertyId={property.propertyId}
                                                    type={1}
                                                    confirmMessage={t("list.pressAgain")}
                                                    label={t("list.aptDoor")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </main>

            <SwipeableDrawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
                <div tabIndex={0} role="button" className={classes.drawer}>
                    <div className={classes.drawerInput}>
                        <Typography variant="h6" color="inherit">
                            {t("list.filter")}
                        </Typography>

                        <FormControlLabel
                            control={<Switch checked={Boolean(filters.showCheckinsToday)} onChange={handleToggle} value="showCheckinsToday" color="primary" />}
                            label={t("list.showCheckinsToday")}
                        />
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    );
}

export default VacantList;
