import React from "react";
import { useConfig } from "../../context/ConfigContext";

type LogoType = {
    className?: any;
};

function Logo({ className }: LogoType) {
    const {
        config: { appIcon }
    } = useConfig();

    return React.cloneElement(appIcon, { className });
}

export default Logo;
