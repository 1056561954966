import React, { useContext, useState, useEffect } from "react";
import configuration from "../appConfig";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const ConfigContext = React.createContext<any>(undefined);

const ConfigProvider = ({ children }: any) => {
    const [config, setConfig] = useState<object | null>(null);
    const [theme, setTheme] = useState<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);

        setConfig(configuration);

        const appTheme = createTheme({
            palette: {
                primary: { main: configuration.primary, contrastText: "#ffffff" },
                secondary: { main: configuration.secondary, contrastText: "#ffffff" }
            }
        });

        setTheme(appTheme);
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <ConfigContext.Provider
            value={{
                config
            }}
        >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ConfigContext.Provider>
    );
};

export function useConfig() {
    const auth = useContext(ConfigContext);
    if (auth == null) {
        throw new Error("You must call useConfig only when inside a ConfigProvider.");
    }
    return auth;
}

const ConfigConsumer = ConfigContext.Consumer;

export { ConfigProvider, ConfigContext, ConfigConsumer };
