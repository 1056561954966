import EventAvailableIcon from "@material-ui/icons/EventAvailable";

const configuration = {
    appName: "vacantApp",
    appIcon: <EventAvailableIcon />,
    primary: "#4a158c",
    secondary: "#9a5ce6"
};

export default configuration;
